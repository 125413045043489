import * as React from 'react';

import {
  Button,
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
} from 'wix-ui-tpa/cssVars';
import { useSettingsEvents } from '../../../../../../contexts/SettingsEvents/SettingsEvents';
import { IStyledButtonProps } from '../interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes, st } from './SidebarLayoutButton.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useCallback, useEffect, useRef } from 'react';

export const SidebarLayoutButton: FCWithChildren<IStyledButtonProps> = (
  props,
) => {
  const { buttonState: SettingsEventsButtonState } = useSettingsEvents();
  const { isMobile } = useEnvironment();
  const {
    buttonState = SettingsEventsButtonState,
    className,
    size = ButtonSIZE.small,
    fullWidth = isMobile,
    priority = ButtonPRIORITY.primary,
    moveFocus,
  } = props;
  const [disabled, setDisabled] = React.useState(props.disabled);
  const nextRef = useRef(null);

  useEffect(() => {
    if (moveFocus && nextRef.current && !disabled) {
      nextRef.current.focus();
    }
  }, [nextRef.current, disabled, moveFocus]);
  const onClick = props.onClick;
  const onClickHandler = useCallback(
    (e: any) => {
      setDisabled(true);
      // This is a workaround to improve INP performance for the action.
      // It makes next paint prior to the heavy action.
      setTimeout(() => {
        setDisabled(props.disabled);
        onClick(e);
      });
    },
    [onClick, props.disabled],
  );

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  return (
    <Button
      fullWidth={fullWidth}
      data-hook={props.dataHook || null}
      className={st(classes.root, { buttonState }, className)}
      disabled={disabled}
      priority={priority}
      size={size}
      ref={nextRef}
      onClick={onClickHandler}
      onBlur={() => {
        console.log('onBlur');
      }}
      onFocus={() => {
        console.log('onFocus');
      }}
    >
      {props.children}
    </Button>
  );
};
